import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import Headline from '../elements/headline'
import Paragraph from '../elements/paragraph'

const PodloveDeepLinking = () => (
  <Layout
    hero={[
      'Podlove Deep Linking',
      'Podlove Deep Linking is a method for describing direct access to audio or video content in Podcast Feeds. It is a very simple extension to a feed that tells a podcast client how to link back to a certain point in time on the podcast’s web site.',
    ]}
  >
    <Seo title="Podlove Deep Linking" />
    <div
      className="
        flex
        flex-wrap
        items-stretch
        ml-auto
        mr-auto
        mt-16
        pb-20
        max-w-full
        md:max-w-2xl
        px-4
      "
    >
      <Paragraph>The same addressing scheme can also be used by web-based media players to construct links to specific points or ranges in the media, either by generating URLs for users to pass on or to be updated in the URL field of the browser.</Paragraph>
      <Headline level={2}>Change Log</Headline>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          <p>2012-05-07: 1.1 - Significantly changed spec to be a subset of Media Fragments URI</p>
          <p>2012-05-07: 1.0 - Initial Release</p>
        </code>
      </pre>
      <Headline level={2}>Motivation and Scope</Headline>
      <Paragraph>There is usually a huge disconnect between podcast clients playing back content and the content creators presence on the web. Listeners may want to refer to certain parts of a show to either “bookmark” something or share it with other people on the web. This is be done by giving away a simple link that people can use to directly access that particular content.</Paragraph>
      <Paragraph>In order to allow websites to directly react to those links, the website must communicate which URL to use to link to content to the podcast client. It does this by providing a simple base URL that the podcast client can extend to include the bookmarked point in time. The website can then extract the time information from that URL and pass it along to a web player that can start playback at the exact time given.</Paragraph>
      <Headline level={2}>Media Fragments URIs</Headline>
      <Paragraph>This specification relates to the Media Fragments URI 1.0 recommendation of the W3C [1] but clients must only use the temporal fragment dimension and the normal play time (npt) syntax to reference points in time or time ranges. Web Players conforming to this specification do not need to support additional fragment dimensions or time notations.</Paragraph>
      <Headline level={2}>Including Player Information in a Podcast Feed</Headline>
      <Paragraph>In order to provide playback URL information in your podcast feed, you have to put in an extra element <code>&lt;atom:link&gt;</code> in each of your episode entries.</Paragraph>
      <Paragraph>This element’s ref attribute needs to be set to the URI value <code>https://podlove.org/deep-link/</code> to denote the proper and Atom-compliant meaning to the podcast client.</Paragraph>
      <Paragraph>The <code>href</code> attribute then specifies the base URL to be appended with time range information. Please replace the example given here with whatever your podcast publication platform requires.</Paragraph>
      <Paragraph>Please understand that this link is just a base URL that needs to be extended with proper time range information by the client. The link alone does merely communicate to the client that the web page in question is actually able to accept media fragment URIs.</Paragraph>
      <Headline level={3} color="blue-400">Example</Headline>
      <Paragraph>This is how that element would show up in an Atom podcast feed:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<?xml version="1.0" encoding="utf-8"?>
<feed xmlns="http://www.w3.org/2005/Atom">
  ...
  <entry>
    <link href="http://mypodcast.com/episode/index.html" />
    <link rel="http://podlove.org/deep-link" href="http://mypodcast.com/episode/player.php?episode=23#" />
    ...
  </entry>
  ...
</feed>`}
        </code>
      </pre>
      <Paragraph>This is how it would look like in an RSS 2.0 podcast feed:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>{`<?xml version="1.0" encoding="UTF-8"?>
<rss version="2.0" xmlns:atom="http://www.w3.org/2005/Atom">
  ...
  <item>
    <link>http://mypodcast.com/episode/index.html</link>
    <atom:link rel="http://podlove.org/deep-link" href="http://mypodcast.com/episode/player.php?episode=23#" />
    ...
  </item>
  ...
</rss>`}</code>
      </pre>
      <Headline level={2}>Appending Time Range Information</Headline>
      <Paragraph>The podcast client appends the time range selected by the user. The podcast specifies the selected range in the “normal play time” syntax of the Media Fragment specification.</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      ><code>{'t=[npt:][start][,end]'}</code></pre>
      <Paragraph>If you omit the start time, it defaults to the beginning of the media file, if you omit end it defaults to the end of the media file.</Paragraph>
      <Paragraph>Here are some examples that show how the time field can be used:</Paragraph>
      <dl
        className="
          text-blue-600
          bg-blue-100
          rounded-lg
          py-6
          px-4
          mb-10
          w-full
          space-y-2
        "
      >
        <dt><code>t=1:35:52,1:42:16</code></dt>
        <dd>play from 01:35:52 to 01:42:16</dd>
        <dt><code>t=263</code></dt>
        <dd>start playback at 4 minutes and 23 seconds</dd>
        <dt><code>t=,24:12</code></dt>
        <dd>play from beginning to 24 minutes and 12 seconds</dd>
      </dl>
      <Paragraph>For more examples and a full specification of the Normal Play Time syntax, consult the Media Fragments <a className="text-turquoise-500 font-semibold" href="https://www.w3.org/TR/media-frags/" rel="noreferrer" target="_blank">[1]</a> specification.</Paragraph>
      <Headline level={2}>Additional Fragment Parameters</Headline>
      <Paragraph>The Media Fragments <a className="text-turquoise-500 font-semibold" href="https://www.w3.org/TR/media-frags/" rel="noreferrer" target="_blank">[1]</a> specification requires the media fragment specifier to be a parameter following a hash sign (“#”) and allows for additional parameters to be passed along. As the podcast client is expected to add just the time range information, any additional parameters that your web player might need must be included in the URL stated in the feed following the fragment identifier.</Paragraph>
      <Paragraph>The Podcast Client MUST honor existing parameters and append the time specification with an ampersand sign (“&”) if a parameter already exists. The following URL</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      ><code>{'http://mypodcast.com/episode/player.php?episode=23#style=external'}</code></pre>
      <Paragraph>must be extended as described to look something like this:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      ><code>{'http://mypodcast.com/episode/player.php?episode=23#style=external&t=10,200'}</code></pre>
      <Paragraph>Right now, this specification does not define any other parameters but might in do so in a future version.</Paragraph>
      <Headline level={2}>Website Player Behavior</Headline>
      <Paragraph>Once the deep link information has been placed in the podcast feed, the website referenced SHOULD be ready to play back the media content directly. The website player SHOULD start playing at the given start time and SHOULD pause playback after the optionally given end time is reached for the first time.</Paragraph>
      <Paragraph>It is up to the player to decide if playback should be immediate after loading the URL or if the user still needs to explicitly start playback using UI controls.</Paragraph>
    </div>
  </Layout>
)

export default PodloveDeepLinking
